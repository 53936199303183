<template>
    <section class="container-page">
        <div class="px-3 py-4">
            <div class="text-center text-white">
                <h2>Complete</h2>
                <p class="mt-3">Content is almost complete</p>
            </div>
            <div class="d-flex justify-content-center">
                <div class="content-container text-white">
                    <div class="d-flex justify-content-center px-3 py-4">
                        <div class="card editable-field-container">
                            <div class="card-body">
                                <div class="form-group">
                                    <div class="d-flex">
                                        <label type="button" @click="$bvModal.show('upload-image')">
                                            <div class="d-flex img-wrap">
                                                <img v-if="images_cover != '-' && images_cover != '' && images_cover != null && images_cover != undefined && images_cover != 'undefined'"
                                                    :src="images_cover" alt="" class="img-fluid">
                                                <img v-else src="@/assets/images/bg-cover-quiz.svg" alt="" class="img-fluid" />
                                                <div class="img__description_layer">
                                                    <p class="img__description">Click to change image</p>
                                                </div>
                                            </div>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="txt-nama-quiz" class="form-label">Nama Course*</label>
                                    <!-- <input type="text" class="form-control" id="txt-nama-quiz" v-model="$store.state.dataQuiz.quiz_name" /> -->
                                    <b-alert variant="danger" :show="$store.state.validationCreate == true" fade dismissible>
                                        Nama Quiz tersebut sudah pernah kamu buat !
                                    </b-alert>
                                    <input type="text" class="form-control" id="txt-nama-quiz"
                                        :class="quiz_name == null || quiz_name == '' ? 'is-invalid' : ''" v-model="quiz_name" />
                                </div>
                                <div class="form-group">
                                    <label for="txt-deskripsi-quiz" class="form-label">Quiz Description</label>
                                    <textarea v-model="quiz_description" class="form-control" id="txt-deskripsi-quiz" rows="4"></textarea>
                                </div>
                                <div class="form-group">
                                    <label for="dd-subject" class="form-label">Category*</label>
                                    <b-alert variant="danger" :show="category_id == null || category_id == ''" fade dismissible>
                                        Category cannot be empty
                                    </b-alert>
                                    <select name="subject" id="dd-subject" class="form-control" v-model="category_id"
                                        @change="changeLearningCategories()">
                                        <option v-if="categories.length == 0" disabled>Tidak ada data</option>
                                        <option v-for="(value, index) in categories" :key="index" :value="value._id">{{value.category_name}}</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label for="tag-list" class="form-label">Tags</label>
                                    <input type="text" v-model="isiTag" id="tag-list" placeholder="Masukan tag" class="form-control"
                                        v-on:keyup.enter="addTag">
                                    <div v-if="tagList.length > 0" class="container-fluid">
                                        <div class="row mt-2">
                                            <div v-for="(tag, index) in tagList" :key="index" class="col-auto my-1">
                                                <div class="tag-badge d-flex align-items-center mr-1">
                                                    <div class="mx-2 my-1 d-flex align-items-center justify-content-center">
                                                        <p class="mb-0 mr-2">#{{ tag }}</p>
                                                        <img src="@/assets/images/icons/close-grey.svg" alt="" style="height: 12px;" type="button"
                                                            @click="removeTag(index)">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mx-4 mt-3 divider"></div>
                        <div class="position-relative">
                            <div class="preview">
                                <img v-if="images_cover != '-' && images_cover != '' && images_cover != null && images_cover != undefined && images_cover != 'undefined'"
                                    :src="images_cover" alt="" class="img-preview">
                                <img v-else src="@/assets/images/bg-cover-quiz.svg" alt="" class="img-preview" />
                                <div class="d-flex justify-content-center">
                                    <div class="audio-player">
                                        <audio
                                            src="https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-61905/zapsplat_multimedia_alert_chime_short_musical_notification_cute_child_like_001_64918.mp3?_=1"
                                            controls="true" class="audio-1"></audio>
                                    </div>
                                </div>
                            </div>
                            <div class="container-public">
                                <div class="d-flex">
                                    <div>
                                        <h3 class="font-weight-normal">Publik</h3>
                                    </div>
                                    <div class="mx-3">
                                        <vs-switch color="success" v-model="formData.public_mode" />
                                    </div>
                                </div>
                                <p class="mb-0 text-left text-muted mt-2">Konten kamu akan dilihat dan diakses oleh semua orang</p>
                            </div>
                            <button class="btn-publish btn-purple px-3 py-2 float-right"> Save & Publish </button>
                        </div>
                    </div>

                    <!-- MODAL UPLOAD IMG CVR -->
                    <b-modal id="upload-image" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true"
                        footer-class="border-0 pb-3 pt-0 mx-auto" size="lg">
                        <!-- <div class="my-4 mx-4">
                                    <div class="text-right">
                                        <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                                    </div>
                                </div> -->
                        <div class="position-relative mt-2">
                            <button class="btn btn-close d-flex justify-content-center align-items-center position-absolute close-image"
                                @click="$bvModal.hide('upload-image')"><b-icon icon="x"></b-icon></button>
                            <vs-tabs :color="colorx">
                                <vs-tab label="Gallery">
                                    <div class="con-tab-ejemplo gallery mt-2">
                                        <label for="search">
                                            <input type="text" v-model="filterThumb" class="search-bar" placeholder="Find thumbnail">
                                        </label>
                                        <div class="container-fluid overflow-auto container-thumbnail mt-3">
                                            <div class="row">
                                                <div v-for="(data, index) in searchThumb" :key="index" class="col-md-3 mb-3 px-0">
                                                    <div class="mr-2 ml-2">
                                                        <button class="btn-thumbnail" @click="fromThumbnail(data.file, data.name)">
                                                            <img :src="data.file" :alt="data.name" class="img-fluid thumbnail-img" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </vs-tab>
                                <vs-tab label="Upload">
                                    <div class="con-tab-ejemplo" @dragover.prevent @dragenter.prevent @dragleave.prevent @drop.prevent>
                                        <b-alert variant="danger" :show="showAlertImg" fade dismissible>
                                            {{ responseValidateImg }}
                                        </b-alert>
                                        <label @drop="dragImage($event)"
                                            class="btn btn-impor-custom w-100 d-flex flex-column py-5 align-items-center text-center mt-3">
                                            <div v-if="images_cover_before != '-' && images_cover_before != '' &&  images_cover_before != null && images_cover_before != undefined"
                                                class="pt-3">
                                                <img :src="images_cover_before" alt="" class="img-form">
                                                <small class="my-auto ml-2">{{ typeof imageFileBefore === "string" ? 'cover_image.jpg' :
                                                    imagenameBefore }}</small>
                                            </div>
                                            <div v-else>
                                                <img src="@/assets/images/logo/paper_impor.svg" alt="">
                                                <h4 class="font-weight-normal mt-2 text-blue">Choose file</h4>
                                                <h5 class="font-weight-normal text-muted mt-1">or drag & drop here</h5>
                                            </div>
                                            <input type="file" style="display: none" @change="imageUpload($event)" />
                                        </label>
                                    </div>
                                </vs-tab>
                            </vs-tabs>
                            <hr>
                            <div class="float-right">
                                <button class="btn btn-secondary btn-md btn-grey text-dark mr-2" @click="cancelImage">
                                    <p class="mb-0 mx-2">Cancel</p>
                                </button>
                                <button class="btn btn-warning btn-md text-white" @click="saveImage">
                                    <p class="mb-0 mx-2">Use</p>
                                </button>
                            </div>
                        </div>
                    </b-modal>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { getMasterThumbnails } from '@/services/master-thumbnails/master-thumbnails.service'
    import {getCategoriesAll} from '@/services/quiz-categories/quiz_categories.service'
    import {getLearningCategories} from '@/services/learning-categories/learning-categories.service'

    export default {
        data() {
            return {
                dataQuiz: {
                    category_id: null,
                    learning_category_id: null,
                    grade_id: null,
                    quiz_name: null,
                    author: null,
                    images_cover: null,
                    categories_quiz: {
                        category_name: null
                    }
                },
                quiz_name: null,
                quiz_description: null,
                category_id: null,
                images_cover: null,
                filterThumb: null,

                categories: [],
                tagList: [],
                masterThumbnails: [],
                isiTag: null,
                showAlertImg: false,
                responseValidateImg: "",
                filename: "",
                imagename: "",
                imagenameBefore: "",
                imageFileBefore: "",
                images_cover_before: "",
                colorx: 'rgb(188, 59, 242)',
                formData: {
                    file: "",
                },
                thumbnail: ["reproduksi.svg", "metabolisme.svg", "tkj.svg", "sosiologi.svg", "geografi.svg", "Digital Marketing.svg", "Bahasa Mandarin.svg", "Bahasa Jepang.svg"],
            }
        },

        computed: {
            searchThumb() {
                if (this.filterThumb) {
                    return this.masterThumbnails.filter((item) => {
                        return this.filterThumb
                            .toLowerCase()
                            .split(" ")
                            .every(
                                (v) =>
                                    item.name.toLowerCase().includes(v)
                            );
                    });
                } else {
                    return this.masterThumbnails;
                }
            },
        },

        watch: {
            quiz_name(newQuizName) {
                localStorage.quiz_name = newQuizName;
            },
            quiz_description(newQuizDescription) {
                localStorage.quiz_description = newQuizDescription;
            },
            images_cover(newImagesCover) {
                localStorage.images_cover = newImagesCover;
            },
            category_id(newCategoryID) {
                localStorage.category_id = newCategoryID;
            },
            listQuestion(newListQuestion) {
                localStorage.listQuestionCreate = JSON.stringify(newListQuestion);
            },
            grade(newGrade) {
                localStorage.grade = newGrade;
            },
            sub_grade(newSubGrade) {
                localStorage.sub_grade = newSubGrade;
            }
            // isiTag(newTag){
            //     localStorage.createTag = JSON.stringify(newTag)
            // }
        },

        methods: {
            setSideBar() {
                this.$store.commit('IS_SIDEBAR_ACTIVE', false);
            },

            getThumbnail() {
                getMasterThumbnails()
                    .then((response) => {
                        if (response.status && response.status == true) {
                            this.masterThumbnails = response.data
                        }
                    })
            },

            getCategories(){
                getCategoriesAll()
                .then(response => {
                    if(response.status && response.status == true){    
                        this.categories = response.data
                    }
                }).catch(error => {
                    console.log(error)
                })
            },

            getLearningCategories(id){
                getLearningCategories(id)
                .then(response => {
                    if(response.status && response.status == true){    
                        this.learnings = response.data
                    }
                }).catch(error => {
                    console.log(error)
                })
            },

            dragImage(e) {
                const file = [...e.dataTransfer.files];

                let size = file[0].size;

                var fileSizes = this.formatBytes(size);

                let type = file[0].type;
                if (
                    type == "image/png" ||
                    type === "image/jpeg"
                ) {
                    if (fileSizes > 25000000) {
                        this.showAlertImg = true;
                        this.responseValidateImg = "Ukuran file melebihi 25MB";
                    } else {
                        this.showAlertImg = false;
                        this.imagenameBefore = file[0].name
                            ? file[0].name
                            : "Undefined";
                        this.imageFileBefore = file[0];
                        this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                        this.convertImage(this.imageFileBefore, 'images_cover')
                    }
                } else {
                    this.showAlertImg = true;
                    this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
                }
            },

            formatBytes(bytes, decimals = 2) {
                if (bytes === 0) return "0 Bytes";

                const k = 1024;
                const dm = decimals < 0 ? 0 : decimals;
                const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

                const i = Math.floor(Math.log(bytes) / Math.log(k));

                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
            },

            convertImage(file, form) {
                var vm = this;
                var reader = new FileReader();
                reader.onloadend = function () {
                    switch (form) {
                        case 'images_cover':
                            vm.images_cover_before = reader.result
                            break;
                        default:
                            break;
                    }
                };
                reader.readAsDataURL(file);
            },

            imageUpload(e) {
                let size = e.target.files[0].size;

                var fileSizes = this.formatBytes(size);

                let type = e.target.files[0].type;
                if (
                    type == "image/png" ||
                    type === "image/jpeg"
                ) {
                    if (fileSizes > 25000000) {
                        this.showAlertImg = true;
                        this.responseValidateImg = "Ukuran file melebihi 25MB";
                    } else {
                        this.showAlertImg = false;
                        this.imagenameBefore = e.target.files[0].name
                            ? e.target.files[0].name
                            : "Undefined";
                        this.imageFileBefore = e.target.files[0];
                        this.images_cover_before = URL.createObjectURL(this.imageFileBefore)
                        this.convertImage(this.imageFileBefore, 'images_cover')
                    }
                } else {
                    this.showAlertImg = true;
                    this.responseValidateImg = "File harus bertipe PNG, JPG, JPEG";
                }
            },

            saveImage() {
                this.imagename = this.imagenameBefore
                this.imageFile = this.imageFileBefore
                this.images_cover = this.images_cover_before

                this.imagenameBefore = ""
                this.imageFileBefore = ""
                this.images_cover_before = ""
                this.$bvModal.hide('upload-image')
            },

            fromThumbnail(image, name){
                this.imagenameBefore = name
                this.imageFileBefore = image
                this.images_cover_before = image
                // this.images_cover = this.images_cover_before
            },

            cancelImage() {
                this.imagenameBefore = ""
                this.imageFileBefore = ""
                this.images_cover_before = ""
                this.showAlertImg = false;
                this.$bvModal.hide('upload-image')
            },

            addTag(e) {
                if (e.target.value != null && e.target.value != '') {
                    this.tagList.push(e.target.value)
                    localStorage.setItem('createTag', JSON.stringify(this.tagList))
                }
                this.isiTag = ''
            },

            removeTag(index) {
                this.tagList.splice(index, 1)
                localStorage.setItem('createTag', JSON.stringify(this.tagList))
            },
        },

        mounted() {
            this.setSideBar();
            this.getThumbnail();
            this.getCategories();
            this.getLearningCategories(this.$store.state.dataQuiz.category_id)
        },
    }
</script>

<style scoped>
    .container-page {
        /* margin-left: -260px; */
        min-height: calc(100vh - 120px);
        height: 100%;
    }
    .content-container {
        background-color: #222222;
        border-radius: 10px;
        width: 60vw;
    }
    .editable-field-container {
        width: 25vw;
    }
    .preview {
        padding: 1.25rem;
        width: 28vw;
        text-align: start;
    }
    .form-label {
        float: left;
    }
    table, th, td {
        border: 0;
    }
    tr,td{
        padding-top: 1rem;
    }

    .form-control{
        background-color: #222222;
        color: white;
        border-color: #383838;
    }

    hr{
        background : #8C8C8C;
    }
    .img-form {
        width: 70px;
        height: 70px;
    }
    .text-blue{
        color: #2B87E3;
    }
    .row .col-3, .row .col-9 {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .btn-impor-custom{
        background-color: #E5E5E5;
        border: 1px solid #D9D9D9;
        height: 14rem;
    }
    .btn-close{
        background-color: #EDEDED;
        border-radius: 50%;
        width: 35px;
        height: 35px;
    }
    .tag-badge{
        width: auto;
        border: 0;
        border-radius: 15px;
        background-color: #2F2F2F;
        -moz-appearance:none !important;
        -webkit-appearance: none !important; 
        appearance: none !important;
        height: 28px
    }
    .questions .card .card-body {
        padding-left: 2.25rem;
    }
    .card {
        border-radius: 10px;
    }
    .search-bar {
        height: 3rem;
        width: 100%;
        border-radius: 25px;
        border: 1px solid #E5E5E5;
        padding-left: 48px !important;
        padding-right: 20px;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
    }
    .gallery label {
        position: relative;
        width: 100%;
        height: 40px;
        border-radius: 25px !important;
        z-index: 2;
    }
    .gallery label:before {
        content: "";
        position: absolute;
        left: 1rem;
        top: 10px;
        bottom: 0;
        width: 40px;
        background: url("~@/assets/images/search-icon.svg") center / contain no-repeat;
        background-size: 20px;
    }
    .thumbnail-img {
        border-radius: 10px 10px 10px 10px
    }
    .btn-thumbnail{
        background-color: transparent;
        border: none;
    }
    .btn-thumbnail:focus img {
        border: 3px solid #BC3BF2;
        border-radius: 10px;
    }
    .container-thumbnail{
        height: 28rem;
    }
    .container-thumbnail::-webkit-scrollbar {
        width: 6px;
    }
        /* Track */
    .container-thumbnail::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }  
        /* Handle */
    .container-thumbnail::-webkit-scrollbar-thumb {
        background: #B6B6B6; 
        border-radius: 4px;
    }
        /* Handle on hover */
    .container-thumbnail::-webkit-scrollbar-thumb:hover {
        background: #888; 
    }
    .close-image{
        right : 5px;
        z-index: 100;
    }
    .btn-grey{
        background-color: #C4C4C4 !important;
        border: 0px !important;
    }
    .img-wrap{
        position: relative;
    }
    .img-wrap img{
        border-radius: 10px;
        max-width: 100%;
    }
    .img__description_layer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 10px;
        color: #fff;
        visibility: hidden;
        opacity: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        /* transition effect. not necessary */
        transition: opacity .2s, visibility .2s;
    }
    .img-wrap:hover .img__description_layer {
        visibility: visible;
        opacity: 1;
    }
    .img__description {
        transition: .2s;
        transform: translateY(1em);
    }
    .img-wrap:hover .img__description {
        transform: translateY(0);
    }
    .btn-purple {
        background-color: #6D75F6 !important;
        border: 1px solid #6D75F6 !important;
    }
    .divider {
        border-left: 2px solid #383838;
        height: 75vh;
    }
    .preview {
        position: relative;
    }
    .container-public {
        padding: 1.25rem;
    }
    .preview .audio-player {
        position: absolute;
        bottom: 1.5rem;
    }
    .img-preview {
        width: 25vw;
        border-radius: 10px;
    }
    .preview audio {
        width: 23vw;
        height: 40px;
    }
    audio::-webkit-media-controls-panel {
        width: 100%;
    }
    .btn-publish {
        color: white;
        border-radius: 8px;
    }
    .btn-publish {
        position: absolute;
        bottom: 3rem;
        right: 1.25rem;
    }
</style>